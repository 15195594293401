const DEV = process.env.NODE_ENV === 'development'

import Rellax from 'rellax'
import { gsap } from 'gsap'
import Swiper, { Navigation, EffectCreative } from 'swiper'

// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-creative'

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init common')
    const isHome = document.querySelector('.home') ? true : false

    // init desktop menu
    // alert('init')
    const left_links = document.querySelectorAll(
      '#big-menu-nav > li.menu-item-has-children'
    )
    const submenus = document.querySelectorAll('#big-menu li .sub-menu')

    const accordionLinks = document.querySelectorAll(
      '#mobile-menu .menu-item-has-children'
    )

    left_links.forEach((link) => {
      link.getElementsByTagName('a')[0].addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        left_links.forEach((other) => {
          other.classList.remove('active')
        })
        link.classList.add('active')
        let target_id = link.getAttribute('id')
        console.log('#big-menu li.' + target_id + ' .sub-menu')
        let target = document.querySelector(
          '#big-menu li.' + target_id + ' .sub-menu'
        )
        submenus.forEach((otherSub) => {
          otherSub.classList.remove('sub-menu-open')
          otherSub.classList.add('sub-menu-closed')
        })
        target.classList.remove('sub-menu-closed')
        target.classList.add('sub-menu-open')
      })
    })

    accordionLinks.forEach((link) => {
      link.getElementsByTagName('a')[0].addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (link.classList.contains('open')) {
          link.classList.remove('open')
        } else {
          accordionLinks.forEach((other) => {
            other.classList.remove('open')
          })
          link.classList.add('open')
        }
      })
    })

    const carousels = document.querySelectorAll('.swiper-carousel')

    // Carousel

    if (carousels) {
      carousels.forEach((carousel, i) => {
        const swiper = new Swiper(carousel, {
          modules: [Navigation],
          slidesPerView: 1.2,
          centeredSlides: true,
          loop: true,
          freeMode: true,
          spaceBetween: 23,

          breakpoints: {
            768: {
              slidesPerView: 1.6,
            },
            960: {
              slidesPerView: 2.2,
            },
            1024: {
              slidesPerView: 4,
              centeredSlides: false,
              navigation: {
                nextEl: '.car-next',
                prevEl: '.car-prev',
              },
            },
          },
        })
      })
    }

    const rellax = new Rellax('.rellax', {
      breakpoints: [640, 768, 1280],
    })

    const hero = document.querySelector('.hero')

    let isScrolled = false

    const header = document.querySelector('header')

    // handle header position on scroll

    let hidden = false,
      isStatic = true

    window.addEventListener('scroll', (ev) => {
      if (hero.getBoundingClientRect().top < window.innerHeight * -1) {
        gsap.to('header', {
          backgroundColor: '#000',
          duration: 0.2,
        })
        gsap.to('.site_logo', {
          opacity: 1,
          duration: 0.2,
        })

        isScrolled = true
      } else {
        gsap.to('header', {
          backgroundColor: 'transparent',
          duration: 0.2,
        })
        gsap.to('.site_logo', {
          opacity: isHome ? 0 : 1,
          duration: 0.2,
        })

        isScrolled = false
      }
    })

    // hide label on items with content
    const onInputBlur = (ev) => {
      if (ev.target && ev.target.value) {
        ev.target.closest('.input-field').classList.add('input-filled')
      } else {
        ev.target.closest('.input-field').classList.remove('input-filled')
      }
    }

    const onInputFocus = (ev) => {
      ev.target.closest('.input-field').classList.add('input-filled')
    }

    const inputFields = document.querySelectorAll('.wpcf7-form-control')
    const inputLabels = document.querySelectorAll('.input-field label')

    if (inputFields) {
      inputFields.forEach((el) => {
        el.addEventListener('blur', onInputBlur)
        el.addEventListener('focus', onInputFocus)
      })

      inputLabels.forEach((el, i) => {
        if (inputFields[i].value !== '') {
          el.closest('.input-field').classList.add('input-filled')
        }
        el.addEventListener('click', (e) => {
          inputFields[i].focus()
        })
      })
    }

    // tigger navbar
    const hamburger = document.querySelector('.menu_btn')
    const navbar = document.querySelector('.navbar')

    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('opened')
      navbar.classList.toggle('opened')
      document.body.classList.toggle('overflow-hidden')

      if (!document.querySelector('.sub-menu-open')) {
        left_links[0].classList.add('active')
        let target_id = left_links[0].getAttribute('id')
        let target = document.querySelector(
          '#big-menu li.' + target_id + ' .sub-menu'
        )
        submenus.forEach((otherSub) => {
          otherSub.classList.remove('sub-menu-open')
          otherSub.classList.add('sub-menu-closed')
        })
        target.classList.remove('sub-menu-closed')
        target.classList.add('sub-menu-open')
      }

      if (hamburger.classList.contains('opened')) {
        gsap.to('.site_logo', { delay: 0.1, opacity: 1 })
        gsap.to('.lang-menu', { delay: 0.15, opacity: 1, zIndex: 3 })
      } else {
        gsap.to('.lang-menu', { opacity: 0, zIndex: -1, duration: 0.04 })
        if (!isScrolled) {
          gsap.to('.site_logo', { opacity: isHome ? 0 : 1, duration: 0.04 })
        }
      }
    })

    function appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')
    }

    window.addEventListener('resize', appHeight)
    appHeight()

    const contactButtons = document.querySelectorAll('.za_btn__contact')
    if (contactButtons) {
      contactButtons.forEach((contactButton) => {
        if (contactButton.hash) {
          const target = document.querySelector(contactButton.hash)

          contactButton.addEventListener('click', (e) => {
            if (target) {
              e.preventDefault()
              target.scrollIntoView()
            }
          })
        }
      })
    }

    // full width slideshows
    const fwslideshows = document.querySelectorAll('.swiper-fw_slideshow')
    if (fwslideshows) {
      fwslideshows.forEach((el, i) => {
        const swiper = new Swiper(el, {
          modules: [Navigation, EffectCreative],
          effect: 'creative',
          creativeEffect: {
            prev: {
              shadow: true,
              translate: ['-35%', 0, -1],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          },
          slidesPerView: 1,
          loop: true,
          grabCursor: true,
          spaceBetween: 0,
          speed: 600,
          navigation: {
            nextEl: '.fw-next',
            prevEl: '.fw-prev',
          },
        })
      })
    }

    const videoTriggers = document.querySelectorAll('.video-trigger')
    if (videoTriggers) {
      videoTriggers.forEach((videoTrigger) => {
        const videoTriggerData = videoTrigger.dataset.vt
        const videoClose = document.querySelector('.lb-close')
        const lighbox = document.querySelector(
          `.lightbox[data-lb="${videoTriggerData}"]`
        )
        const iframe = lighbox.querySelector('iframe')
        const iframeSrc = iframe.src
        videoTrigger.addEventListener('click', () => {
          iframe.src = iframeSrc
          lighbox.classList.add('opened')
          c
        })
        videoClose.addEventListener('click', () => {
          lighbox.classList.remove('opened')
          iframe.src = ''
        })
      })
    }

    // ajax
    jQuery('.products__list').each(function () {
      const wrapper = jQuery(this)
      let currentPage = 1
      let currentTerms
      const trigger = wrapper.find('.load-more')
      trigger.on('click', function () {
        currentTerms = trigger.data('type')
        currentPage++

        jQuery.ajax({
          type: 'POST',
          url: '/wp-admin/admin-ajax.php',
          dataType: 'json',
          data: {
            action: 'za_load_more',
            paged: currentPage,
            terms: currentTerms,
          },
          success: function (res) {
            if (currentPage >= res.max) {
              trigger.hide()
            }

            wrapper.append(res.html)
          },
        })
      })
    })
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('finalize common')
  },
}
